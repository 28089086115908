import clsx from "clsx";
import { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useShallow } from "zustand/react/shallow";
import {
  getOccupancySummary,
  useBookingStore,
} from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Date from "../../ui/Date";
import NumberInput from "../../ui/NumberInput";
import Switch from "../../ui/Switch";
import { maxExtraQuantity } from "../../utils/constants";
import { formatIntlDate, parseDate } from "../../utils/date";
import styles from "./ExtraPerGuestAndDay.module.css";
import ExtraPerGuestAndDayChildren from "./ExtraPerGuestAndDayChildren";
import { ExtraFormType } from "./utils";

enum View {
  Adults,
  Children,
}

const ExtraPerGuestAndDay = () => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const occupancySummary = getOccupancySummary(
    useBookingStore(
      useShallow((state) => ({
        autoOccupancy: state.autoOccupancy,
        occupancies: state.occupancies,
      })),
    ),
  );

  const [view, setView] = useState(View.Adults);

  const { control, setValue, watch } = useFormContext<ExtraFormType>();
  const { fields: quantities } = useFieldArray({
    name: "quantities",
    control,
  });

  const watchFieldArray = watch("quantities");
  const controlledFields = quantities.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const allChecked = controlledFields.every((field) => {
    if (view === View.Adults) {
      return field.adults === occupancySummary.adults;
    }
    return (
      occupancySummary.children ===
      field.children?.filter((x) => x.age >= 0).length
    );
  });
  const toggleInputs = () => {
    controlledFields.forEach((_field, index) => {
      if (view === View.Adults) {
        setValue(
          `quantities.${index}.adults`,
          allChecked ? 0 : occupancySummary.adults,
        );
      } else {
        setValue(
          `quantities.${index}.children`,
          allChecked
            ? []
            : occupancySummary.childrenAges.map((age) => ({ age })),
        );
      }
    });
  };

  return (
    <>
      {occupancySummary.children > 0 && (
        <div className={styles.views}>
          <Button
            layout="round"
            active={view === View.Adults}
            buttonProps={{
              onClick: () => setView(View.Adults),
              title: i18n.extras.adults,
            }}
          >
            {i18n.extras.adults}
          </Button>
          <Button
            layout="round"
            active={view === View.Children}
            buttonProps={{
              onClick: () => setView(View.Children),
              title: i18n.extras.children,
            }}
          >
            {i18n.extras.children}
          </Button>
        </div>
      )}
      <div className={styles.switchAll}>
        <div>
          {view === View.Adults
            ? i18n.extras.allAdults
            : i18n.extras.allChildren}
        </div>
        <Switch checked={allChecked} onChange={() => toggleInputs()} />
      </div>
      <div className={styles.inputs}>
        {controlledFields.map((quantity, index) => (
          <div key={quantity.key}>
            <Controller
              key={quantity.id}
              name={`quantities.${index}.adults`}
              control={control}
              rules={{
                min: 0,
                max: Math.min(
                  maxExtraQuantity - (quantity.children?.length ?? 0),
                  occupancySummary.adults,
                ),
              }}
              render={({ field: { value } }) => (
                <NumberInput
                  className={clsx({
                    [styles.hide]: view !== View.Adults,
                  })}
                  title={
                    <Date
                      date={parseDate(quantity.key)}
                      formatter={formatIntlDate(language)}
                    />
                  }
                  value={value ?? 0}
                  min={0}
                  max={Math.min(
                    maxExtraQuantity - (quantity.children?.length ?? 0),
                    occupancySummary.adults,
                  )}
                  onChange={(quantity) =>
                    setValue(`quantities.${index}.adults`, quantity, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    })
                  }
                />
              )}
            />
            {occupancySummary.children > 0 && (
              <ExtraPerGuestAndDayChildren
                className={clsx({
                  [styles.hide]: view !== View.Children,
                })}
                quantity={quantity}
                quantityIndex={index}
                childrenAges={occupancySummary.childrenAges}
                maxChildrenCount={Math.min(
                  maxExtraQuantity - (quantity.adults ?? 0),
                  occupancySummary.children,
                )}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ExtraPerGuestAndDay;
