import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import Date from "../../ui/Date";
import NumberInput from "../../ui/NumberInput";
import { maxExtraQuantity } from "../../utils/constants";
import { formatIntlDate, parseDate } from "../../utils/date";
import styles from "./ExtraPerDay.module.css";
import { ExtraFormType } from "./utils";

const ExtraPerDay = () => {
  const language = useBookingStore((state) => state.language);
  const { control, setValue, watch } = useFormContext<ExtraFormType>();
  const { fields: quantities } = useFieldArray({
    name: "quantities",
    control,
  });

  const watchFieldArray = watch("quantities");
  const controlledFields = quantities.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  return (
    <div className={styles.inputs}>
      {controlledFields.map((quantity, index) => (
        <Controller
          key={quantity.id}
          name={`quantities.${index}.quantity`}
          control={control}
          rules={{
            min: 0,
            max: maxExtraQuantity,
          }}
          render={({ field: { value } }) => (
            <NumberInput
              title={
                <Date
                  date={parseDate(quantity.key)}
                  formatter={formatIntlDate(language)}
                />
              }
              value={value ?? 0}
              min={0}
              max={maxExtraQuantity}
              onChange={(quantity) =>
                setValue(`quantities.${index}.quantity`, quantity, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            />
          )}
        />
      ))}
    </div>
  );
};

export default ExtraPerDay;
