import { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { BookingStep } from "../../@types";
import {
  getExtraConfiguration,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import {
  isModalActive,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { Extra as ExtraType, useExtras } from "../../http/extraApi";
import { translate } from "../../i18n";
import PageWithHeader from "../../pages/PageWithHeader";
import Button from "../../ui/Button";
import { Card } from "../../ui/Card";
import CenterWrapper from "../../ui/CenterWrapper";
import FullscreenModal from "../../ui/FullscreenModal";
import Headline from "../../ui/Headline";
import Check from "../../ui/icon/check.svg?react";
import Price from "../../ui/icon/price.svg?react";
import ResponsiveImage from "../../ui/ResponsiveImage";
import { formatMoney } from "../../utils/number";
import ExtraConfigurationForm from "./ExtraConfigurationForm";
import styles from "./Extras.module.css";
import ExtraSummary from "./ExtraSummary";

const Extras = () => {
  const checkoutData = useBookingStore((state) => state.checkoutData);
  const language = useBookingStore((state) => state.language);
  const updateCheckoutData = useBookingStore(
    (state) => state.updateCheckoutData,
  );
  const setStep = useBookingStoreSetStep();
  const i18n = translate(language);
  const { data: extras, isLoading } = useExtras();
  const [selectedCategory, setSelectedCategory] = useState("");
  const includedExtras =
    checkoutData?.extras.filter((extra) => extra.is_included) ?? [];

  useEffect(() => {
    if (!isLoading && extras?.extras.length === 0) {
      setStep(BookingStep.Checkout, true);
    }
  }, [extras, setStep, isLoading]);

  return (
    <PageWithHeader
      header={
        <div className={styles.headlineWrapper}>
          <div className={styles.includedExtras}>
            <Headline
              as="div"
              size={3}
              title={i18n.extras.includedExtras}
              className={styles.includedExtrasHeadline}
            />
            {includedExtras.map((extra) => (
              <ExtraSummary key={extra.id} extra={extra} />
            ))}
          </div>
          <Headline
            as="h1"
            size={2}
            title={
              includedExtras.length > 0
                ? i18n.extras.selectAdditionalExtras
                : i18n.extras.selectExtras
            }
          />
        </div>
      }
    >
      {extras && !isLoading && (
        <>
          <div className={styles.categoriesWrapper}>
            <div className={styles.categories}>
              <Button
                layout="round"
                active={!selectedCategory}
                buttonProps={{
                  title: i18n.extras.all(extras.extras.length),
                  onClick: () => setSelectedCategory(""),
                }}
              >
                {i18n.extras.all(extras.extras.length)}
              </Button>
              {extras.extra_categories.map((category) => {
                const categoryTitle = `${category.title} (${extras.extras.filter((extra) => extra.category_id === category.id).length})`;
                return (
                  <Button
                    key={category.id}
                    layout="round"
                    active={selectedCategory === category.id}
                    buttonProps={{
                      title: categoryTitle,
                      onClick: () => setSelectedCategory(category.id),
                    }}
                  >
                    {categoryTitle}
                  </Button>
                );
              })}
            </div>
          </div>
          <CenterWrapper>
            <div className={styles.extras}>
              {extras.extras
                .filter(
                  (extra) =>
                    !selectedCategory || extra.category_id === selectedCategory,
                )
                .map((extra) => (
                  <Extra key={extra.id} extra={extra} />
                ))}
            </div>
          </CenterWrapper>
        </>
      )}
      <div className={styles.buttonWrapper}>
        <CenterWrapper className={styles.buttonCenterWrapper}>
          <Button
            layout="primary"
            buttonProps={{
              title: i18n.extras.goToCheckout,
              className: styles.continueButton,
              onClick: () => {
                setStep(BookingStep.Checkout);
                updateCheckoutData();
              },
            }}
          >
            {i18n.extras.goToCheckout}
          </Button>
        </CenterWrapper>
      </div>
    </PageWithHeader>
  );
};

interface ExtraProps {
  extra: ExtraType;
}

const Extra = ({ extra }: ExtraProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const modalIdentifier = `Extra_${extra.id}`;
  const toggleDetailModal = useModalHistoryToggle(modalIdentifier);
  const showDetailModal = useModalHistoryStore(isModalActive(modalIdentifier));
  const extraConfiguration = useBookingStore(
    useShallow(getExtraConfiguration(extra.id)),
  );
  const buttonTitle = extraConfiguration
    ? i18n.extras.edit
    : i18n.extras.select;

  return (
    <>
      <FullscreenModal
        fullWidth
        title={extra.title}
        isOpen={showDetailModal}
        headerContent={
          <div className={styles.priceBadge}>
            <Price className={styles.priceIcon} aria-hidden />
            {formatMoney(extra.price_per_unit, language)}
          </div>
        }
        content={
          <ExtraConfigurationForm
            extra={extra}
            onSubmit={() => toggleDetailModal(false)}
          />
        }
        onClose={() => toggleDetailModal(false)}
      />
      <Card
        header={<ExtraHeader extra={extra} selected={!!extraConfiguration} />}
        footer={
          <Button
            layout="secondary"
            buttonProps={{
              title: buttonTitle,
              onClick: () => toggleDetailModal(true),
            }}
          >
            {buttonTitle}
          </Button>
        }
      >
        <Button
          layout="link"
          buttonProps={{
            title: extra.title,
            onClick: () => toggleDetailModal(true),
            className: styles.extraName,
          }}
        >
          {extra.title}
        </Button>
        <div className={styles.price}>
          <Headline
            title={`+${formatMoney(extra.price_per_unit, language)}`}
            size={1}
            as="div"
          />
        </div>
        <div>{i18n.extras[extra.type]}</div>
      </Card>
    </>
  );
};

interface ExtraHeaderProps {
  extra: ExtraType;
  selected: boolean;
}

const ExtraHeader = ({ extra, selected }: ExtraHeaderProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <div className={styles.extraHeader}>
      {extra.photos[0] && (
        <ResponsiveImage
          lazyLoad
          alt={extra.title}
          className={styles.extraImage}
          srcSet={extra.photos[0].derivatives}
          sizes="(min-width: 921px) 33vw, (min-width: 614px) 50vw, 100vw"
        />
      )}
      {selected && (
        <div className={styles.selectedBadge}>
          <Check className={styles.selectedBadgeIcon} aria-hidden />
          {i18n.extras.selected}
        </div>
      )}
    </div>
  );
};

export default Extras;
