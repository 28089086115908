import { Field, Label, Select } from "@headlessui/react";
import clsx from "clsx";
import { FocusEventHandler, Ref, useId, useState } from "react";
import { FieldError } from "react-hook-form";
import FormError from "./FormError";
import styles from "./FormSelect.module.css";

interface Option {
  key: string;
  value: string;
  selected?: boolean;
}

interface FormSelectProps {
  label: string;
  options: Option[];
  required?: boolean;
  isEmpty?: boolean;
  autoComplete?: string;
  className?: string;
  error?: FieldError | undefined;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  ref: Ref<HTMLSelectElement>;
}

const FormSelect = ({
  label,
  options,
  required,
  isEmpty,
  autoComplete,
  className,
  error,
  onBlur,
  ref,
  ...rest
}: FormSelectProps) => {
  const [focused, setFocused] = useState(false);
  const [autoFilled, setAutoFilled] = useState(false);
  const errorId = useId();

  return (
    <Field
      className={clsx(styles.selectWrapper, className, {
        [styles.isInvalid]: !!error,
      })}
    >
      <Label
        className={clsx(styles.label, {
          [styles.focused]: focused,
          [styles.filled]: !isEmpty || autoFilled,
        })}
      >
        {label + (required ? "*" : "")}
      </Label>
      <Select
        {...rest}
        ref={ref}
        autoComplete={autoComplete}
        className={styles.select}
        aria-invalid={!!error}
        aria-required={required}
        aria-errormessage={error ? errorId : undefined}
        onFocus={() => setFocused(true)}
        onBlur={(...args) => {
          setFocused(false);
          onBlur?.(...args);
        }}
        /* required to trigger animation on autofill preview which then can be detected via javascript */
        onAnimationStart={(e) => {
          switch (e.animationName) {
            case styles["on-auto-fill-start"]:
              setAutoFilled(true);
              break;

            case styles["on-auto-fill-cancel"]:
              setAutoFilled(false);
              break;
          }
        }}
      >
        {options.map((option: Option) => (
          <option key={option.key} value={option.key}>
            {option.value}
          </option>
        ))}
      </Select>
      <FormError id={errorId} errorMessage={error?.message ?? ""} />
    </Field>
  );
};

export default FormSelect;
