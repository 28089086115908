import { AutoOccupancy, Occupancy, RoomConfiguration } from "../@types";

export const adultsSearchParamKey = (occupancyIndex?: number): string =>
  occupancyIndex === undefined
    ? `auto_occupancy[adults]`
    : `occupancies[${occupancyIndex}][adults]`;

export const childrenSearchParamKey: {
  (occupancyIndex: number, childrenIndex: number): string;
  (childrenIndex: number): string;
} = (occupancyIndex: number, childrenIndex?: number): string => {
  if (childrenIndex === undefined) {
    return `auto_occupancy[children][${occupancyIndex}]`;
  }
  return `occupancies[${occupancyIndex}][children][${childrenIndex}]`;
};

export const roomTypeIdSearchParamKey = (occupancyIndex?: number): string =>
  occupancyIndex === undefined
    ? `auto_occupancy[room_type_id]`
    : `occupancies[${occupancyIndex}][room_type_id]`;

export const ratePlanIdSearchParamKey = (occupancyIndex?: number): string =>
  occupancyIndex === undefined
    ? `auto_occupancy[rate_plan_id]`
    : `occupancies[${occupancyIndex}][rate_plan_id]`;

export const boardTypeSearchParamKey = (occupancyIndex?: number): string =>
  occupancyIndex === undefined
    ? `auto_occupancy[board_type]`
    : `occupancies[${occupancyIndex}][board_type]`;

interface SetOccupancySearchParamsArguments {
  autoOccupancy?: AutoOccupancy | null;
  occupancies?: Occupancy[];
  roomTypeId?: string | null;
  ratePlanId?: string | null;
}
export const setOccupancySearchParams = (
  searchParams: URLSearchParams,
  {
    autoOccupancy,
    occupancies,
    roomTypeId,
    ratePlanId,
  }: SetOccupancySearchParamsArguments,
) => {
  if (autoOccupancy) {
    const occupancy = autoOccupancy;
    if (!occupancy.adults) {
      return;
    }

    searchParams.set(adultsSearchParamKey(), occupancy.adults.toString());
    occupancy.children.forEach((childAge, j) => {
      searchParams.set(childrenSearchParamKey(j), childAge.toString());
    });

    if (roomTypeId) {
      searchParams.set(roomTypeIdSearchParamKey(), roomTypeId);
    }

    if (ratePlanId) {
      searchParams.set(ratePlanIdSearchParamKey(), ratePlanId);
    }
  } else {
    occupancies?.forEach((occupancy, i) => {
      if (!occupancy.adults) {
        return;
      }

      searchParams.set(adultsSearchParamKey(i), occupancy.adults.toString());
      occupancy.children.forEach((childAge, j) => {
        searchParams.set(childrenSearchParamKey(i, j), childAge.toString());
      });
      if (occupancy.roomTypeId) {
        searchParams.set(roomTypeIdSearchParamKey(i), occupancy.roomTypeId);
      }
    });
  }
};

interface SetOccupancySearchParamsArgumentsByRoomConfigurations {
  roomConfigurations: RoomConfiguration[];
}
export const setOccupancySearchParamsByRoomConfigurations = (
  searchParams: URLSearchParams,
  { roomConfigurations }: SetOccupancySearchParamsArgumentsByRoomConfigurations,
) => {
  roomConfigurations.forEach((roomConfiguration, i) => {
    if (!roomConfiguration.adults) {
      return;
    }

    searchParams.set(
      adultsSearchParamKey(i),
      roomConfiguration.adults.toString(),
    );
    roomConfiguration.children?.forEach((childAge, j) => {
      searchParams.set(childrenSearchParamKey(i, j), childAge.toString());
    });
    if (roomConfiguration.roomTypeId) {
      searchParams.set(
        roomTypeIdSearchParamKey(i),
        roomConfiguration.roomTypeId,
      );
    }
    if (roomConfiguration.ratePlanId) {
      searchParams.set(
        ratePlanIdSearchParamKey(i),
        roomConfiguration.ratePlanId,
      );
    }
    if (roomConfiguration.boardType) {
      searchParams.set(boardTypeSearchParamKey(i), roomConfiguration.boardType);
    }
  });
};
