import { Fieldset, Legend, RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { FieldSetting } from "../../@types";
import { useBookingStore } from "../../hooks/useBookingStore";
import {
  isModalActive,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { Gender, postBooking, PrepaymentType } from "../../http/bookingApi";
import { useCountries } from "../../http/countryApi";
import { SelectedOfferResponse } from "../../http/offerApi";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Checkbox from "../../ui/Checkbox";
import Form from "../../ui/Form";
import FormError from "../../ui/FormError";
import FormSelect from "../../ui/FormSelect";
import FullscreenModal from "../../ui/FullscreenModal";
import Headline from "../../ui/Headline";
import Input from "../../ui/Input";
import Radio from "../../ui/Radio";
import Textarea from "../../ui/Textarea";
import { zeroPrice } from "../../utils/constants";
import { formatMoney } from "../../utils/number";
import { site } from "../../utils/site";
import { sanitize } from "../../utils/string";
import ExtraSuggestion from "../extra/ExtraSuggestion";
import styles from "./CheckoutForm.module.css";
import CheckoutVoucher from "./CheckoutVoucher";
import { CheckoutForm as CheckoutFormType, GuestType } from "./utils";

interface CheckoutFormProps {
  checkoutData: SelectedOfferResponse | null;
}

const CheckoutForm = ({ checkoutData }: CheckoutFormProps) => {
  const language = useBookingStore((state) => state.language);
  const { data: countries } = useCountries();
  const i18n = translate(language);

  const insuranceModalIdentifier = `CheckoutForm_Insurance`;
  const setShowInsuranceModal = useModalHistoryToggle(insuranceModalIdentifier);
  const showInsuranceModal = useModalHistoryStore(
    isModalActive(insuranceModalIdentifier),
  );

  const reservationPoliciesModalIdentifier = `CheckoutForm_ReservationPolicies`;
  const setShowReservationPoliciesModal = useModalHistoryToggle(
    reservationPoliciesModalIdentifier,
  );
  const showReservationPoliciesModal = useModalHistoryStore(
    isModalActive(reservationPoliciesModalIdentifier),
  );

  const submitBooking = async (values: CheckoutFormType) => {
    await postBooking({
      "offer_selections":
        checkoutData?.rooms.map((room) => ({
          adults: room.occupancy.adults,
          children: room.occupancy.children ?? [],
          "room_type_id": room.room_type_id,
          "rate_plan_id": room.rate_plan.id,
          "board_type": room.board_type,
        })) ?? [],
      guest: {
        gender: values.gender,
        "first_name": values.first_name,
        "last_name": values.last_name,
        "email_address": values.email_address,
        "phone_number": values.phone_number ?? "",
        note: values.note ?? "",
        street: values.street ?? "",
        "postal_code": values.postal_code ?? "",
        municipality: values.municipality ?? "",
        country: values.country ?? "",
        company: {
          name: values.company.name,
          "vat_number": values.company.vat_number,
          "recipient_code": values.company.recipient_code ?? "",
          street: values.company.street ?? "",
          "postal_code": values.company.postal_code ?? "",
          municipality: values.company.municipality ?? "",
          country: values.company.country ?? "",
        },
      },
      insurance: values.insurance,
      "voucher_code": values.voucher_code ?? "",
      "prepayment_type": values.prepayment_type,
    });
    // TODO: redirect to payment page
    window.location.href = "/de/booking-success?booking_id=123";
  };

  return (
    <div className={styles.form}>
      <Headline
        as="h2"
        size={2}
        title={i18n.checkout.formHeadline}
        className={styles.headline}
      />
      <Form<CheckoutFormType>
        mode="onSubmit"
        defaultValues={{
          gender: Gender.None,
          "first_name": "",
          "last_name": "",
          "email_address": "",
          "phone_number": "",
          note: "",
          street: "",
          "postal_code": "",
          municipality: "",
          country: "",
          company: {
            name: "",
            "vat_number": "",
            "recipient_code": "",
            street: "",
            "postal_code": "",
            municipality: "",
            country: "",
          },
          guestType: GuestType.Private,
          insurance: false,
          "prepayment_type": PrepaymentType.Undefined,
        }}
        onSubmit={submitBooking}
      >
        {({ register, formState: { errors }, watch, control }) => (
          <>
            <Fieldset className={styles.block}>
              <Legend className={styles.blockHeadline}>
                {i18n.checkout.yourData[site.guest_interaction]}
              </Legend>
              <div className={styles.fields}>
                <FormSelect
                  label={i18n.checkout.gender.gender}
                  isEmpty={!watch("gender")}
                  error={errors.gender}
                  options={[
                    {
                      key: Gender.Male,
                      value: i18n.checkout.gender[Gender.Male],
                    },
                    {
                      key: Gender.Female,
                      value: i18n.checkout.gender[Gender.Female],
                    },
                  ]}
                  disabled={
                    site.guest_field_settings.gender === FieldSetting.Disabled
                  }
                  required={
                    site.guest_field_settings.gender === FieldSetting.Required
                  }
                  {...register("gender", {
                    required: {
                      value:
                        site.guest_field_settings.gender ===
                        FieldSetting.Required,
                      message: i18n.general.form.errors.required,
                    },
                  })}
                />
                <Input
                  label={i18n.checkout.firstName}
                  error={errors.first_name}
                  isEmpty={!watch("first_name")}
                  autoComplete="given-name"
                  required
                  {...register("first_name", {
                    required: {
                      value: true,
                      message: i18n.general.form.errors.required,
                    },
                  })}
                />
                <Input
                  label={i18n.checkout.lastName}
                  error={errors.last_name}
                  isEmpty={!watch("last_name")}
                  autoComplete="family-name"
                  required
                  {...register("last_name", {
                    required: {
                      value: true,
                      message: i18n.general.form.errors.required,
                    },
                  })}
                />
                <Input
                  type="email"
                  label={i18n.checkout.email}
                  error={errors.email_address}
                  isEmpty={!watch("email_address")}
                  autoComplete="email"
                  required
                  {...register("email_address", {
                    required: {
                      value: true,
                      message: i18n.general.form.errors.required,
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message:
                        i18n.general.form.errors.email[site.guest_interaction],
                    },
                  })}
                />
                <Input
                  type="tel"
                  label={i18n.checkout.phone}
                  error={errors.phone_number}
                  isEmpty={!watch("phone_number")}
                  autoComplete="tel"
                  disabled={
                    site.guest_field_settings.phone_number ===
                    FieldSetting.Disabled
                  }
                  required={
                    site.guest_field_settings.phone_number ===
                    FieldSetting.Required
                  }
                  {...register("phone_number", {
                    required: {
                      value:
                        site.guest_field_settings.phone_number ===
                        FieldSetting.Required,
                      message: i18n.general.form.errors.required,
                    },
                  })}
                />
                <Input
                  label={i18n.checkout.street}
                  error={errors.street}
                  isEmpty={!watch("street")}
                  autoComplete="street-address"
                  disabled={
                    site.guest_field_settings.street === FieldSetting.Disabled
                  }
                  required={
                    site.guest_field_settings.street === FieldSetting.Required
                  }
                  {...register("street", {
                    required: {
                      value:
                        site.guest_field_settings.street ===
                        FieldSetting.Required,
                      message: i18n.general.form.errors.required,
                    },
                  })}
                />
                <Input
                  label={i18n.checkout.postalCode}
                  error={errors.postal_code}
                  isEmpty={!watch("postal_code")}
                  autoComplete="postal-code"
                  disabled={
                    site.guest_field_settings.postal_code ===
                    FieldSetting.Disabled
                  }
                  required={
                    site.guest_field_settings.postal_code ===
                    FieldSetting.Required
                  }
                  {...register("postal_code", {
                    required: {
                      value:
                        site.guest_field_settings.postal_code ===
                        FieldSetting.Required,
                      message: i18n.general.form.errors.required,
                    },
                  })}
                />
                <Input
                  label={i18n.checkout.municipality}
                  error={errors.municipality}
                  isEmpty={!watch("municipality")}
                  autoComplete="address-level2"
                  disabled={
                    site.guest_field_settings.municipality ===
                    FieldSetting.Disabled
                  }
                  required={
                    site.guest_field_settings.municipality ===
                    FieldSetting.Required
                  }
                  {...register("municipality", {
                    required: {
                      value:
                        site.guest_field_settings.municipality ===
                        FieldSetting.Required,
                      message: i18n.general.form.errors.required,
                    },
                  })}
                />
                {countries?.countries && (
                  <FormSelect
                    label={i18n.checkout.country}
                    error={errors.country}
                    isEmpty={!watch("country")}
                    autoComplete="country"
                    options={countries.countries.map((country) => ({
                      key: country.code,
                      value: country.name,
                    }))}
                    disabled={
                      site.guest_field_settings.country ===
                      FieldSetting.Disabled
                    }
                    required={
                      site.guest_field_settings.country ===
                      FieldSetting.Required
                    }
                    {...register("country", {
                      required: {
                        value:
                          site.guest_field_settings.country ===
                          FieldSetting.Required,
                        message: i18n.general.form.errors.required,
                      },
                    })}
                  />
                )}
              </div>
            </Fieldset>
            <Fieldset className={styles.block}>
              <Legend className={styles.blockHeadline}>
                {i18n.checkout.company[site.guest_interaction]}
              </Legend>
              <Controller
                control={control}
                name="guestType"
                render={({ field }) => (
                  <RadioGroup className={styles.radioButtons} {...field}>
                    <Radio
                      label={i18n.checkout.company.yes}
                      value={GuestType.Company}
                    />
                    <Radio
                      label={i18n.checkout.company.no}
                      value={GuestType.Private}
                    />
                  </RadioGroup>
                )}
              />
            </Fieldset>
            {watch("guestType") === GuestType.Company && (
              <Fieldset className={clsx(styles.block, styles.smallSpacing)}>
                <Legend className={styles.blockHeadline}>
                  {i18n.checkout.companyInvoiceData[site.guest_interaction]}
                </Legend>
                <div className={styles.companyFields}>
                  <Input
                    label={i18n.checkout.company.name}
                    error={errors.company?.name}
                    isEmpty={!watch("company.name")}
                    autoComplete="organization"
                    required={watch("guestType") === GuestType.Company}
                    {...register("company.name", {
                      required: {
                        value: watch("guestType") === GuestType.Company,
                        message: i18n.general.form.errors.required,
                      },
                    })}
                  />
                  <Input
                    label={i18n.checkout.company.vatNumber}
                    error={errors.company?.vat_number}
                    isEmpty={!watch("company.vat_number")}
                    required={watch("guestType") === GuestType.Company}
                    {...register("company.vat_number", {
                      required: {
                        value: watch("guestType") === GuestType.Company,
                        message: i18n.general.form.errors.required,
                      },
                    })}
                  />
                  <Input
                    label={i18n.checkout.company.recipientCode}
                    isEmpty={!watch("company.recipient_code")}
                    {...register("company.recipient_code")}
                  />
                  <Input
                    label={i18n.checkout.company.street}
                    isEmpty={!watch("company.street")}
                    autoComplete="street-address"
                    {...register("company.street")}
                  />
                  <Input
                    label={i18n.checkout.company.postalCode}
                    isEmpty={!watch("company.postal_code")}
                    autoComplete="postal-code"
                    {...register("company.postal_code")}
                  />
                  <Input
                    label={i18n.checkout.company.municipality}
                    isEmpty={!watch("company.municipality")}
                    autoComplete="address-level2"
                    {...register("company.municipality")}
                  />
                  {countries?.countries && (
                    <FormSelect
                      label={i18n.checkout.company.country}
                      isEmpty={!watch("company.country")}
                      autoComplete="country"
                      options={countries.countries.map((country) => ({
                        key: country.code,
                        value: country.name,
                      }))}
                      {...register("company.country")}
                    />
                  )}
                </div>
              </Fieldset>
            )}
            <Fieldset className={styles.block}>
              <Legend className={styles.blockHeadline}>
                {i18n.checkout.voucher[site.guest_interaction]}
              </Legend>
              <CheckoutVoucher />
            </Fieldset>
            {(checkoutData?.extra_suggestions.length ?? 0) > 0 && (
              <>
                <div className={styles.block}>
                  <Headline
                    as="div"
                    size={3}
                    title={
                      i18n.checkout.extraSuggestions[site.guest_interaction]
                    }
                  />
                </div>
                {checkoutData?.extra_suggestions.map((extraSuggestion) => (
                  <ExtraSuggestion
                    key={extraSuggestion.id}
                    extraSuggestion={extraSuggestion}
                  />
                ))}
              </>
            )}
            <Fieldset className={styles.block}>
              <Legend className={styles.blockHeadline}>
                {i18n.checkout.note}
              </Legend>
              <Headline size={4} title={i18n.checkout.noteDescription} />
              <Textarea
                className={styles.noteField}
                label={i18n.checkout.noteLabel}
                isEmpty={!watch("note")}
                {...register("note")}
              />
            </Fieldset>
            <Fieldset className={styles.block}>
              <Legend className={styles.blockHeadline}>
                {i18n.checkout.paymentInfo}
              </Legend>
              <Headline
                size={4}
                title={i18n.checkout.paymentAmount(
                  formatMoney(
                    checkoutData?.prepayment.price ?? zeroPrice,
                    language,
                  ),
                  checkoutData?.prepayment.percentage ?? 0,
                )}
              />
            </Fieldset>
            <Fieldset className={clsx(styles.block, styles.smallSpacing)}>
              <Legend className={styles.blockHeadline}>
                {`${i18n.checkout.paymentType}*`}
              </Legend>
              <Controller
                control={control}
                name="prepayment_type"
                rules={{
                  required: {
                    value: true,
                    message: i18n.general.form.errors.required,
                  },
                }}
                render={({ field, fieldState }) => (
                  <>
                    <RadioGroup
                      className={styles.radioButtons}
                      {...field}
                      aria-required
                      aria-invalid={!!fieldState.error}
                      aria-errormessage={
                        fieldState.error ? "prepayment_type_error" : undefined
                      }
                    >
                      <Radio
                        label={i18n.checkout.card}
                        value={PrepaymentType.Card}
                      />
                      <Radio
                        label={i18n.checkout.bankTransfer}
                        value={PrepaymentType.BankTransfer}
                      />
                    </RadioGroup>
                    <FormError
                      id="prepayment_type_error"
                      errorMessage={fieldState.error?.message ?? ""}
                    />
                  </>
                )}
              />
            </Fieldset>
            {checkoutData?.insurance && (
              <Fieldset className={styles.block}>
                <Legend className={styles.blockHeadline}>
                  {i18n.checkout.insurance}
                </Legend>
                <Controller
                  control={control}
                  name="insurance"
                  render={({ field }) => (
                    <Checkbox
                      label={i18n.checkout.insuranceLabel(
                        checkoutData.insurance?.name ?? "",
                        formatMoney(
                          checkoutData.insurance?.price ?? zeroPrice,
                          language,
                        ),
                      )}
                      {...field}
                    />
                  )}
                />
                {checkoutData.insurance.description && (
                  <>
                    <Button
                      layout="link"
                      buttonProps={{
                        className: styles.showMoreInsuranceInfosButton,
                        title: i18n.checkout.showMoreInsuranceDetails,
                        onClick: () => setShowInsuranceModal(true),
                      }}
                    >
                      {i18n.checkout.showMoreInsuranceDetails}
                    </Button>
                    <FullscreenModal
                      title={checkoutData.insurance.name}
                      content={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(
                              checkoutData.insurance.description,
                            ),
                          }}
                        />
                      }
                      isOpen={showInsuranceModal}
                      onClose={() => setShowInsuranceModal(false)}
                    />
                  </>
                )}
              </Fieldset>
            )}
            {checkoutData?.reservation_policies && (
              <div className={styles.block}>
                <Headline
                  as="div"
                  size={3}
                  title={i18n.checkout.reservationPolicies}
                  className={styles.blockHeadline}
                />
                <ul className={styles.reservationPolicies}>
                  {checkoutData.reservation_policies.summary.map(
                    (condition, index) => (
                      <li key={index}>{condition}</li>
                    ),
                  )}
                </ul>
                <Button
                  layout="link"
                  buttonProps={{
                    className: styles.showMoreReservationPoliciesButton,
                    title: i18n.ratePlans.moreDetails,
                    onClick: () => setShowReservationPoliciesModal(true),
                  }}
                >
                  {i18n.ratePlans.moreDetails}
                </Button>
                <FullscreenModal
                  isOpen={showReservationPoliciesModal}
                  onClose={() => setShowReservationPoliciesModal(false)}
                  title={i18n.ratePlans.reservationPolicies}
                  content={
                    <ul className={styles.reservationPolicies}>
                      {checkoutData.reservation_policies.full.map(
                        (condition, index) => (
                          <li key={index}>{condition}</li>
                        ),
                      )}
                    </ul>
                  }
                />
              </div>
            )}
            <div className={clsx(styles.block, styles.buttonBlock)}>
              <Headline
                size={2}
                title={i18n.checkout.bookingSummary[site.guest_interaction]}
                className={styles.bookingButtonHeadline}
              />
              <Headline
                size={1}
                title={formatMoney(
                  checkoutData?.prices.total ?? zeroPrice,
                  language,
                )}
                className={styles.bookingAmount}
              />
              <Controller
                control={control}
                name="privacyPolicy"
                rules={{
                  required: {
                    value: true,
                    message: i18n.general.form.errors.required,
                  },
                }}
                render={({ field }) => (
                  <Checkbox
                    className={styles.privacyCheckbox}
                    label={i18n.checkout.privacyPolicy}
                    error={errors.privacyPolicy}
                    required
                    {...field}
                  />
                )}
              />
              {/* TODO: Insert the privacy and terms links. */}
              <Button
                buttonProps={{
                  type: "submit",
                  title: i18n.checkout.book,
                  className: styles.bookingButton,
                }}
              >
                {i18n.checkout.book}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default CheckoutForm;
