import useSWR from "swr";
import {
  Image,
  Language,
  Price,
  Prices,
  ReservationPolicies,
  TotalPrices,
} from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { formatDateNullable } from "../utils/date";
import {
  setOccupancySearchParams,
  setOccupancySearchParamsByRoomConfigurations,
} from "../utils/occupancy";
import { PrepaymentType } from "./bookingApi";
import { ExtraType } from "./extraApi";
import { BoardType } from "./ratePlanApi";
import { api, fetcher } from "./utils";

interface Offers {
  offers: Offer[];
}

export interface Offer {
  room_type_id: string;
  name: string;
  room_index: number;
  available_rooms: number | null;
  cheapest_prices: Prices;
  rates: Rates[];
}

export interface OfferBoard {
  board_type: BoardType;
  surcharge: Price;
}

interface Rates {
  rate_plan_id: string;
  prices: Prices;
  board_types: OfferBoard[];
}

// TODO: replace with live url: `/properties/${propertyId}/offers`
const offersURL = "/offers.json";

export const useOffers = () => {
  const language = useBookingStore((state) => state.language);
  const occupancies = useBookingStore((state) => state.occupancies);
  const roomConfigurations = useBookingStore(
    (state) => state.roomConfigurations,
  );
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);

  const { data, ...rest } = useSWR(
    () => {
      const formattedArrival = formatDateNullable(arrival);
      if (!formattedArrival) {
        return null;
      }

      const formattedDeparture = formatDateNullable(departure);
      if (!formattedDeparture) {
        return null;
      }

      const searchParams = new URLSearchParams();
      searchParams.set("arrival", formattedArrival);
      searchParams.set("departure", formattedDeparture);

      if (roomConfigurations.length) {
        setOccupancySearchParamsByRoomConfigurations(searchParams, {
          roomConfigurations,
        });
      } else {
        setOccupancySearchParams(searchParams, {
          occupancies,
        });
      }

      const searchParamsString = searchParams.toString();
      return {
        url: offersURL + (searchParamsString ? `?${searchParamsString}` : ""),
        language,
      };
    },
    async (opts) => {
      const { offers } = await fetcher<Offers>(opts);

      return {
        offers,
        offersMap: Object.fromEntries(
          offers.map((x) => [`${x.room_index}_${x.room_type_id}`, x]),
        ),
      };
    },
  );

  return {
    ...rest,
    ...data,
  };
};

interface AutoOffers {
  auto_offers: AutoOffer[];
}

export interface AutoOffer {
  total_prices: Prices;
  rooms: AutoOfferRoom[];
  roomsMap: Record<string, AutoOfferRoom>;
}

export interface AutoOfferRoom {
  room_type_id: string;
  name: string;
  room_index: number;
  available_rooms: number | null;
  cheapest_prices: Prices;
  occupancy: {
    adults: number;
    children?: number[];
  };
  rates: Rates[];
}

// TODO: replace with live url: `/properties/${propertyId}/auto_offers`
const autoOffersURL = "/auto-offers-de.json";

export const useAutoOffers = () => {
  const language = useBookingStore((state) => state.language);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);

  const { data, ...rest } = useSWR(
    () => {
      const formattedArrival = formatDateNullable(arrival);
      if (!formattedArrival) {
        return null;
      }

      const formattedDeparture = formatDateNullable(departure);
      if (!formattedDeparture) {
        return null;
      }

      const searchParams = new URLSearchParams();
      searchParams.set("arrival", formattedArrival);
      searchParams.set("departure", formattedDeparture);

      setOccupancySearchParams(searchParams, {
        autoOccupancy,
      });

      const searchParamsString = searchParams.toString();
      return {
        url:
          autoOffersURL + (searchParamsString ? `?${searchParamsString}` : ""),
        language,
      };
    },
    async (opts) => {
      const { auto_offers: autoOffers } = await fetcher<AutoOffers>(opts);

      autoOffers.forEach((ao) => {
        ao.roomsMap = Object.fromEntries(
          ao.rooms.map((x) => [`${x.room_index}_${x.room_type_id}`]),
        );
      });

      return {
        autoOffers,
      };
    },
  );

  return {
    ...rest,
    ...data,
  };
};

interface PortalPrices {
  portal_prices: PortalPrice[];
}

interface PortalPrice {
  name: string;
  room_index?: number;
  icon: Image;
  price: Price;
}

// TODO: replace with live url: `/properties/${propertyId}/portal_prices`
const portalPricesURL = "/portal-prices.json";

export const usePortalPrices = () => {
  const occupancies = useBookingStore((state) => state.occupancies);
  const roomConfigurations = useBookingStore(
    (state) => state.roomConfigurations,
  );
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);

  const { data, ...rest } = useSWR(
    () => {
      const formattedArrival = formatDateNullable(arrival);
      if (!formattedArrival) {
        return null;
      }

      const formattedDeparture = formatDateNullable(departure);
      if (!formattedDeparture) {
        return null;
      }

      const searchParams = new URLSearchParams();
      searchParams.set("arrival", formattedArrival);
      searchParams.set("departure", formattedDeparture);

      if (roomConfigurations.length) {
        setOccupancySearchParamsByRoomConfigurations(searchParams, {
          roomConfigurations,
        });
      } else {
        setOccupancySearchParams(searchParams, {
          occupancies,
        });
      }

      const searchParamsString = searchParams.toString();
      return {
        url:
          portalPricesURL +
          (searchParamsString ? `?${searchParamsString}` : ""),
      };
    },
    async (opts) => (await fetcher<PortalPrices>(opts)).portal_prices,
  );

  return {
    ...rest,
    portalPrices: data,
  };
};

export interface OfferSelection {
  adults: number;
  children?: number[];
  room_type_id: string;
  rate_plan_id: string;
  board_type: BoardType | null;
}

export interface SelectedOfferBody {
  offer_selections: OfferSelection[];
  insurance: boolean;
  voucher_code?: string;
  extras?: SelectedOfferExtra[];
}

interface ExtraPerStay {
  per_stay: number;
}
interface ExtraPerDay {
  per_day: Record<string, number>;
}
interface ExtraPerRoom {
  per_room: Record<string, number>;
}
interface ExtraPerGuestAndDay {
  per_guest_and_day: Record<string, { adults: number; children: number[] }>;
}

export type SelectedOfferExtra = (
  | ExtraPerStay
  | ExtraPerDay
  | ExtraPerRoom
  | ExtraPerGuestAndDay
) & {
  id: string;
};

export interface SelectedOfferRoom {
  room_type_id: string;
  name: string;
  occupancy: {
    adults: number;
    children?: number[];
  };
  rate_plan: {
    id: string;
    title: string;
    reservation_policies: string;
  };
  board_type: BoardType;
  price: Price;
}

export interface SelectedOfferExtraResponse {
  id: string;
  title: string;
  is_included: boolean;
  amount: ExtraPerStay | ExtraPerDay | ExtraPerRoom | ExtraPerGuestAndDay;
  total_amount: number;
  total_price: Price;
}

interface ExtendStaySuggestion {
  arrival: string;
  departure: string;
  discount: number;
}

export interface ExtraSuggestion {
  id: string;
  title: string;
  type: ExtraType;
  description: string;
  price_per_unit: Price;
}

export interface SelectedOfferResponse {
  check_in: {
    from: Date;
    to: Date;
  };
  check_out: {
    from: Date;
    to: Date;
  };
  rooms: SelectedOfferRoom[];
  extras: SelectedOfferExtraResponse[];
  prices: TotalPrices;
  prepayment: {
    type: PrepaymentType;
    price: Price;
    percentage: number;
  };
  insurance?: {
    name: string;
    price: Price;
    description: string;
  };
  extend_stay_suggestions: ExtendStaySuggestion[];
  extra_suggestions: ExtraSuggestion[];
  reservation_policies: ReservationPolicies;
}

// TODO: replace with live url: `/properties/${propertyId}/offers`
const postOffersURL = "/post-offers-de.json";
interface PostSelectedOffersOptions {
  arrival: Date | null;
  departure: Date | null;
  language: Language;
  body: SelectedOfferBody;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const postSelectedOffers = (_options: PostSelectedOffersOptions) => {
  // const searchParams = new URLSearchParams();
  // const arrivalString = formatDateNullable(options.arrival);
  // if (arrivalString) {
  //   searchParams.set("arrival", arrivalString);
  // }
  // const departureString = formatDateNullable(options.departure);
  // if (departureString) {
  //   searchParams.set("departure", departureString);
  // }

  // const searchParamsString = searchParams.toString();
  // const url = `${postOffersURL}${searchParamsString ? `?${searchParamsString}` : ""}`;
  // return api
  //   .url(url)
  //   .headers({ "Accept-Language": options.language })
  //   .post(options.body)
  //   .json<SelectedOfferResponse>();
  // TODO: replace with POST request
  return api.url(postOffersURL).get().json<SelectedOfferResponse>();
};
