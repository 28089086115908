import { Field, Input as HeadlessInput, Label } from "@headlessui/react";
import clsx from "clsx";
import { FocusEventHandler, Ref, useId, useState } from "react";
import { FieldError } from "react-hook-form";
import FormError from "./FormError";
import styles from "./Input.module.css";

interface InputProps {
  label: string;
  required?: boolean;
  isEmpty?: boolean;
  autoComplete?: string;
  className?: string;
  error?: FieldError | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  type?: "text" | "email" | "tel";
  ref: Ref<HTMLInputElement>;
}

const Input = ({
  label,
  required,
  isEmpty,
  autoComplete,
  className,
  error,
  onBlur,
  type = "text",
  ref,
  ...rest
}: InputProps) => {
  const [focused, setFocused] = useState(false);
  const [autoFilled, setAutoFilled] = useState(false);
  const errorId = useId();

  return (
    <Field
      className={clsx(styles.inputWrapper, className, {
        [styles.isInvalid]: !!error,
      })}
    >
      <Label
        className={clsx(styles.label, {
          [styles.focused]: focused,
          [styles.filled]: !isEmpty || autoFilled,
        })}
      >
        {label + (required ? "*" : "")}
      </Label>
      <HeadlessInput
        type={type}
        {...rest}
        ref={ref}
        autoComplete={autoComplete}
        className={styles.input}
        aria-invalid={!!error}
        aria-required={required}
        aria-errormessage={error ? errorId : undefined}
        onFocus={() => setFocused(true)}
        onBlur={(...args) => {
          setFocused(false);
          onBlur?.(...args);
        }}
        /* required to trigger animation on autofill preview which then can be detected via javascript */
        onAnimationStart={(e) => {
          switch (e.animationName) {
            case styles["on-auto-fill-start"]:
              setAutoFilled(true);
              break;

            case styles["on-auto-fill-cancel"]:
              setAutoFilled(false);
              break;
          }
        }}
      />
      <FormError id={errorId} errorMessage={error?.message ?? ""} />
    </Field>
  );
};

export default Input;
