import { isSameDay } from "date-fns";
import { BookingStep, HeaderButtonCategory } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import useMediaQuery from "../../hooks/useMediaQuery";
import Form from "../../ui/Form";
import MobileModal from "../../ui/MobileModal";
import CalendarForm from "./CalendarForm";
import styles from "./CalendarModal.module.css";
import { FormDataCalendar } from "./utils";

const Calendar = () => {
  const step = useBookingStore((state) => state.step);
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const setArrivalAndDeparture = useBookingStore(
    (state) => state.setArrivalAndDeparture,
  );
  const activeHeaderButton = useBookingStore(
    (state) => state.activeHeaderButton,
  );
  const setActiveHeaderButton = useBookingStore(
    (state) => state.setActiveHeaderButton,
  );
  const setStep = useBookingStoreSetStep();
  const isDesktop = useMediaQuery("(min-width: 1126px)");
  const nextStep = autoOccupancy
    ? BookingStep.AutoOccupancy
    : BookingStep.RoomConfigurations;

  return (
    <MobileModal
      align="right"
      size="large"
      isOpen={activeHeaderButton === HeaderButtonCategory.Calendar}
      onClose={() => setActiveHeaderButton(HeaderButtonCategory.None)}
    >
      <Form<FormDataCalendar>
        defaultValues={{
          arrival,
          departure,
        }}
        className={styles.form}
        onSubmit={(data) => {
          if (didSomethingChange(data, arrival, departure)) {
            setArrivalAndDeparture({
              arrival: data.arrival,
              departure: data.departure,
            });
            setStep(nextStep);

            return;
          }

          if ([BookingStep.Start, BookingStep.Calendar].includes(step)) {
            setStep(nextStep);
          } else {
            setActiveHeaderButton(HeaderButtonCategory.None);
          }
        }}
      >
        <CalendarForm
          isDesktop={isDesktop}
          key={isDesktop ? "desktop" : "mobile"}
        />
      </Form>
    </MobileModal>
  );
};

export default Calendar;

const didSomethingChange = (
  data: FormDataCalendar,
  arrival: Date | null,
  departure: Date | null,
) => {
  const arrivalChanged = dateChanged(data.arrival, arrival);
  const departureChanged = dateChanged(data.departure, departure);

  return arrivalChanged || departureChanged;
};

const dateChanged = (oldDate: Date | null, newDate: Date | null): boolean => {
  return (
    oldDate !== newDate &&
    (oldDate === null || newDate === null || !isSameDay(oldDate, newDate))
  );
};
