import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { ReactNode } from "react";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import Button from "./Button";
import Headline from "./Headline";
import styles from "./Modal.module.css";
import Close from "./icon/close.svg?react";

interface ModalProps {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  onClose: () => void;
}

const Modal = ({ isOpen, title, children, onClose }: ModalProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <Dialog open={isOpen} onClose={onClose} className={styles.overlay}>
      <DialogPanel className={styles.dialog}>
        <div className={styles.inner}>
          <DialogTitle as="div" className={styles.header}>
            <Headline as="h1" size={2} className={styles.title} title={title} />
            <Button
              layout="link"
              buttonProps={{
                onClick: onClose,
                "aria-label": i18n.general.close,
                className: styles.closeButton,
              }}
            >
              <Close className={styles.icon} />
            </Button>
          </DialogTitle>
          <div className={styles.content}>{children}</div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default Modal;
