import { Field, Radio as HeadlessRadio, Label } from "@headlessui/react";
import styles from "./Radio.module.css";

interface RadioProps {
  label: string;
  value: string | number;
  className?: string;
}

const Radio = ({ label, value, className, ...rest }: RadioProps) => {
  return (
    <Field className={className}>
      <Label className={styles.label}>
        <HeadlessRadio {...rest} className={styles.radio} value={value} />
        {label}
      </Label>
    </Field>
  );
};

export default Radio;
