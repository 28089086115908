import { useBookingStore } from "../../hooks/useBookingStore";
import useToggle from "../../hooks/useToggle";
import { LegalTextType, useLegalTexts } from "../../http/legalTextsApi";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Headline from "../../ui/Headline";
import Modal from "../../ui/Modal";
import { sanitize } from "../../utils/string";
import styles from "./LegalTextMenuItem.module.css";

interface LegalTextMenuItemProps {
  type: LegalTextType;
}

export const LegalTextMenuItem = ({ type }: LegalTextMenuItemProps) => {
  const { language } = useBookingStore();
  const i18n = translate(language);
  const modal = useToggle();
  const { data: legalTexts } = useLegalTexts(modal.isOpen);

  return (
    <>
      <Button
        buttonProps={{
          onClick: () => modal.open(),
        }}
        layout="link"
      >
        {i18n.start.legalMenu[type]}
      </Button>
      {/*
        TODO: Implement skeleton loading for better UX
        instead of opening when loaded.
      */}
      {legalTexts && (
        <Modal
          title={i18n.start.legalMenu[type]}
          isOpen={modal.isOpen}
          onClose={modal.close}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(legalTexts[type]),
            }}
          />
          {type === LegalTextType.Imprint && (
            <section>
              <Headline
                size={3}
                title={i18n.start.legalMenu.imageSources}
                as="h2"
              />
              <ul className={styles.images}>
                {legalTexts.images.map(
                  ({ url, description, copyright, width, height }) => {
                    return (
                      <li key={url}>
                        <figure className={styles.image}>
                          <img
                            src={url}
                            alt={description}
                            width={width}
                            height={height}
                            loading="lazy"
                            className={styles.img}
                          />
                          <figcaption className={styles.copyright}>
                            © {copyright}
                          </figcaption>
                        </figure>
                      </li>
                    );
                  },
                )}
              </ul>
            </section>
          )}
        </Modal>
      )}
    </>
  );
};
