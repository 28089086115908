import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Headline from "../../ui/Headline";
import Switch from "../../ui/Switch";
import { maxExtraQuantity } from "../../utils/constants";
import { site } from "../../utils/site";
import styles from "./ExtraPerRoom.module.css";
import { ExtraFormType } from "./utils";

const ExtraPerRoom = () => {
  const checkoutData = useBookingStore((state) => state.checkoutData);
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const { control, setValue, watch } = useFormContext<ExtraFormType>();
  const { fields: quantities } = useFieldArray({
    name: "quantities",
    control,
  });

  const watchFieldArray = watch("quantities");
  const controlledFields = quantities.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const allChecked = controlledFields.every((field) => field.quantity === 1);
  const toggleInputs = () => {
    controlledFields.forEach((_field, index) => {
      setValue(`quantities.${index}.quantity`, allChecked ? 0 : 1);
    });
  };

  return (
    <>
      {quantities.length > 1 && (
        <div className={styles.switchAll}>
          <div>{i18n.extras.allRooms}</div>
          <Switch checked={allChecked} onChange={() => toggleInputs()} />
        </div>
      )}
      <div className={styles.rooms}>
        {controlledFields.map((quantity, index) => {
          const room = checkoutData?.rooms[index];
          if (!room) {
            return null;
          }

          return (
            <Controller
              key={quantity.id}
              name={`quantities.${index}.quantity`}
              control={control}
              rules={{
                min: 0,
                max: maxExtraQuantity,
              }}
              render={({ field: { value } }) => (
                <div className={styles.room}>
                  <div className={styles.headlineWrapper}>
                    <Headline
                      size={2}
                      title={i18n.extras.room(index + 1, site.room_term)}
                    />
                    <div className={styles.roomName}>{room.name}</div>
                  </div>
                  <Switch
                    checked={value === 1}
                    onChange={(checked) =>
                      setValue(
                        `quantities.${index}.quantity`,
                        checked ? 1 : 0,
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        },
                      )
                    }
                  />
                </div>
              )}
            />
          );
        })}
      </div>
    </>
  );
};

export default ExtraPerRoom;
