import { useShallow } from "zustand/react/shallow";
import { BookingStep, RoomConfiguration } from "../../@types";
import {
  isAutoOfferRoomConfiguration,
  selectCurrentRoomConfiguration,
  selectNights,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import {
  isModalActive,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { OfferBoard, useAutoOffers, useOffers } from "../../http/offerApi";
import { useRatePlans } from "../../http/ratePlanApi";
import { translate } from "../../i18n";
import PageWithHeader from "../../pages/PageWithHeader";
import Button from "../../ui/Button";
import { Card } from "../../ui/Card";
import CenterWrapper from "../../ui/CenterWrapper";
import FullscreenModal from "../../ui/FullscreenModal";
import Headline from "../../ui/Headline";
import ArrowLeft from "../../ui/icon/arrow-left.svg?react";
import Check from "../../ui/icon/check.svg?react";
import ResponsiveImage from "../../ui/ResponsiveImage";
import { formatMoney } from "../../utils/number";
import BoardIcons from "./BoardIcons";
import styles from "./Boards.module.css";
import { Board as BoardType, mergeBoards } from "./utils";

const Boards = () => {
  const isAutoOffer = useBookingStore(isAutoOfferRoomConfiguration);
  const currentRoomConfiguration = useBookingStore(
    useShallow(selectCurrentRoomConfiguration),
  );

  if (!currentRoomConfiguration) {
    return null;
  }

  return isAutoOffer ? (
    <BoardsAutoOffers currentRoomConfiguration={currentRoomConfiguration} />
  ) : (
    <BoardsOffers currentRoomConfiguration={currentRoomConfiguration} />
  );
};

interface BoardsProps {
  currentRoomConfiguration: RoomConfiguration;
}

const BoardsOffers = ({ currentRoomConfiguration }: BoardsProps) => {
  const { offersMap } = useOffers();
  const offer =
    offersMap?.[
      `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
    ];
  const offerBoards = offer?.rates.find(
    (rate) => rate.rate_plan_id === currentRoomConfiguration.ratePlanId,
  )?.board_types;

  if (!offerBoards) {
    return null;
  }

  return (
    <BoardList
      boards={offerBoards}
      currentRoomConfiguration={currentRoomConfiguration}
    />
  );
};

const BoardsAutoOffers = ({ currentRoomConfiguration }: BoardsProps) => {
  const autoOfferIndex = useBookingStore((state) => state.autoOfferIndex);
  const { autoOffers } = useAutoOffers();

  if (!autoOffers || autoOfferIndex === null) {
    return null;
  }

  const offerRoom =
    autoOffers[autoOfferIndex]?.roomsMap[
      `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
    ];
  const offerBoards = offerRoom?.rates.find(
    (rate) => rate.rate_plan_id === currentRoomConfiguration.ratePlanId,
  )?.board_types;

  if (!offerBoards) {
    return null;
  }

  return (
    <BoardList
      boards={offerBoards}
      currentRoomConfiguration={currentRoomConfiguration}
      isAutoOffer
    />
  );
};

interface BoardListProps {
  boards: OfferBoard[];
  currentRoomConfiguration: RoomConfiguration;
  isAutoOffer?: boolean;
}

const BoardList = ({
  boards,
  currentRoomConfiguration,
  isAutoOffer = false,
}: BoardListProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const setStep = useBookingStoreSetStep();
  const { ratePlansMap } = useRatePlans();
  const ratePlanBoards =
    ratePlansMap?.[currentRoomConfiguration.ratePlanId ?? ""]?.boards;

  if (!ratePlanBoards) {
    return null;
  }

  return (
    <PageWithHeader
      header={
        <div className={styles.header}>
          <Button
            layout="link"
            buttonProps={{
              onClick: () =>
                setStep(
                  isAutoOffer
                    ? BookingStep.RatePlansAutoOffer
                    : BookingStep.RatePlans,
                ),
              title: i18n.boards.back,
              className: styles.backButton,
            }}
          >
            <ArrowLeft className={styles.backButtonIcon} />
            <span className={styles.backButtonTitle}>{i18n.boards.back}</span>
          </Button>
          <Headline
            className={styles.headerHeadline}
            as="h1"
            size={2}
            title={i18n.boards.selectBoard}
          />
        </div>
      }
    >
      <CenterWrapper>
        <div className={styles.boards}>
          {mergeBoards(boards, ratePlanBoards).map((board, index) => (
            <Board
              key={index}
              board={board}
              currentRoomConfiguration={currentRoomConfiguration}
            />
          ))}
        </div>
      </CenterWrapper>
    </PageWithHeader>
  );
};

interface BoardProps {
  board: BoardType;
  currentRoomConfiguration: RoomConfiguration;
}

const Board = ({ board, currentRoomConfiguration }: BoardProps) => {
  const language = useBookingStore((state) => state.language);
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );
  const updateCheckoutData = useBookingStore(
    (state) => state.updateCheckoutData,
  );
  const setStep = useBookingStoreSetStep();
  const nights = useBookingStore(selectNights);
  const i18n = translate(language);
  const { adults = 0, children = [] } = currentRoomConfiguration;

  const modalIdentifier = `Board_${board.type}`;
  const toggleDetailModal = useModalHistoryToggle(modalIdentifier);
  const showDetailModal = useModalHistoryStore(isModalActive(modalIdentifier));

  const selectBoard = () => {
    updateRoomConfiguration({ boardType: board.type });
    updateCheckoutData();
    setStep(BookingStep.RoomConfigurations);
  };

  return (
    <>
      <FullscreenModal
        isOpen={showDetailModal}
        title={i18n.boards[board.type]}
        content={<Headline size={4} title={board.description} as="h2" />}
        onClose={() => toggleDetailModal(false)}
      />
      <Card
        header={<BoardHeader board={board} />}
        footer={
          <Button
            buttonProps={{
              title: i18n.boards.continue,
              onClick: selectBoard,
            }}
          >
            {i18n.boards.continue}
          </Button>
        }
      >
        <Button
          layout="link"
          buttonProps={{
            title: i18n.boards[board.type],
            onClick: selectBoard,
            className: styles.boardName,
          }}
        >
          {i18n.boards[board.type]}
        </Button>
        <div className={styles.surcharge}>
          <Headline
            title={`+${formatMoney(board.surcharge, language)}`}
            size={1}
            as="div"
          />
          {board.description && (
            <Button
              layout="link"
              buttonProps={{
                className: styles.moreInfosButton,
                title: i18n.boards.moreInfos,
                onClick: () => toggleDetailModal(true),
              }}
            >
              {i18n.boards.moreInfos}
            </Button>
          )}
        </div>
        {nights && adults && (
          <div>
            {children.length
              ? i18n.boards.nightsAndGuests(nights, adults + children.length)
              : i18n.boards.nightsAndAdults(nights, adults)}
          </div>
        )}
      </Card>
    </>
  );
};

interface BoardHeaderProps {
  board: BoardType;
}

const BoardHeader = ({ board }: BoardHeaderProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const BoardIcon = BoardIcons[board.type];

  return (
    <div className={styles.boardHeader}>
      {board.photo ? (
        <ResponsiveImage
          srcSet={board.photo.derivatives}
          alt={board.description}
          sizes="(min-width: 921px) 33vw, (min-width: 614px) 50vw, 100vw"
          lazyLoad
          className={styles.boardImage}
        />
      ) : (
        BoardIcon && (
          <div className={styles.boardIconWrapper}>
            <BoardIcon className={styles.boardIcon} />
          </div>
        )
      )}
      {board.surcharge.amount <= 0 && (
        <div className={styles.included}>
          <Check className={styles.includedIcon} aria-hidden />
          {i18n.boards.included}
        </div>
      )}
    </div>
  );
};

export default Boards;
