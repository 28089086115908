import useSWR from "swr";
import { Photo, Price } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { formatDateNullable } from "../utils/date";
import { fetcher } from "./utils";

export enum ExtraType {
  PerDay = "per_day",
  PerGuestAndDay = "per_guest_and_day",
  PerRoom = "per_room",
  PerStay = "per_stay",
}

export interface Extra {
  id: string;
  category_id: string;
  title: string;
  type: ExtraType;
  price_per_unit: Price;
  photos: Photo[];
  description?: string;
}

interface Extras {
  extras: Extra[];
  extra_categories: {
    id: string;
    title: string;
  }[];
}

// TODO: replace with live url: `/properties/{property_id}/extras`
const extrasURL = "/extras-de.json";

export const useExtras = () => {
  const language = useBookingStore((state) => state.language);
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);

  const formattedArrival = formatDateNullable(arrival);
  const formattedDeparture = formatDateNullable(departure);

  const searchParams = new URLSearchParams();
  searchParams.set("arrival", formattedArrival ?? "");
  searchParams.set("departure", formattedDeparture ?? "");

  return useSWR(
    { url: `${extrasURL}?${searchParams.toString()}`, language },
    (opts) => fetcher<Extras>(opts),
  );
};
