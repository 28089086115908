import clsx from "clsx";
import {
  addDays,
  addMonths,
  differenceInMonths,
  isBefore,
  isSameMonth,
  startOfMonth,
} from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CalendarMonth,
  DateRange,
  DayPicker,
  Matcher,
  Modifiers,
} from "react-day-picker";
import "react-day-picker/dist/style.css";
import { BookingStep } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { useButtonGroupStore } from "../../hooks/useButtonGroupStore";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import {
  useArrivalAvailabilities,
  useDepartureAvailabilities,
} from "../../http/availabilityApi";
import { translate } from "../../i18n";
import { ActionFooter } from "../../ui/ActionFooter";
import DateComponent from "../../ui/Date";
import { MobileModalContent, MobileModalHeader } from "../../ui/MobileModal";
import {
  calendarMonthLoadingThreshold,
  desktopNumberOfMonths,
  maxMonths,
} from "../../utils/constants";
import { formatIntlDate, parseDate } from "../../utils/date";
import { getLocaleByLanguage } from "../../utils/language";
import { site } from "../../utils/site";
import CalendarDayButton from "./CalendarDayButton";
import CalendarFooter from "./CalendarFooter";
import styles from "./CalendarForm.module.css";
import CalendarHeader from "./CalendarHeader";
import CalendarMonthCaption from "./CalendarMonthCaption";
import {
  CalendarContext,
  CalendarContextValue,
  getMonthsConfig,
  isDayInvalid,
  useCalendarFormContext,
} from "./utils";

interface CalendarFormProps {
  isDesktop: boolean;
}

const CalendarForm = ({ isDesktop }: CalendarFormProps) => {
  const currentDate = useMemo(() => parseDate(site.current_date), []);
  const [initScroll, setInitScroll] = useState(true);
  const [scrollToMonthRef, setScrollToMonthRef] =
    useState<HTMLDivElement | null>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const loadMoreMonthsRef = useRef<HTMLDivElement>(null);
  const setStep = useBookingStoreSetStep();
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const setButtonGroupArrival = useButtonGroupStore(
    (state) => state.setArrival,
  );
  const setButtonGroupDeparture = useButtonGroupStore(
    (state) => state.setDeparture,
  );

  const { register, setValue, watch } = useCalendarFormContext();
  const [arrival, departure] = watch(["arrival", "departure"]);

  const [month, setMonth] = useState(() =>
    isDesktop ? (arrival ?? currentDate) : currentDate,
  );

  const { initialMonthCount } = getMonthsConfig(arrival);

  // arrival availabilities
  const {
    data: arrivalAvailabilities,
    dataMap: arrivalAvailabilitiesMap,
    isLoading: isArrivalAvailabilitiesLoading,
    isValidating: isArrivalAvailabilitiesValidating,
    size: arrivalAvailabilitiesSize,
    setSize: setArrivalAvailabilitiesSize,
  } = useArrivalAvailabilities({
    initialSize: initialMonthCount,
  });

  useIntersectionObserver({
    root: modalContentRef.current,
    target: loadMoreMonthsRef.current,
    rootMargin: `0px 0px ${calendarMonthLoadingThreshold}px 0px`,
    onIntersect: () => {
      setArrivalAvailabilitiesSize(arrivalAvailabilitiesSize + 1);
    },
    enabled:
      !isDesktop &&
      maxMonths > arrivalAvailabilitiesSize &&
      !isArrivalAvailabilitiesLoading &&
      !isArrivalAvailabilitiesValidating,
  });

  // departure availabilities
  const { data: departureAvailabilities } = useDepartureAvailabilities(arrival);

  useEffect(() => {
    if (!initScroll) {
      return;
    }
    const timer = setTimeout(() => {
      scrollToMonthRef?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      setInitScroll(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [initScroll, scrollToMonthRef]);

  // arrival modifier
  const arrivalDays = useMemo(() => {
    return (
      arrivalAvailabilities?.arrival_days.map((arrivalAvailabilityDay) =>
        parseDate(arrivalAvailabilityDay.date),
      ) ?? []
    );
  }, [arrivalAvailabilities]);

  // departure modifier
  const {
    departureDays,
    minStay,
    maxStay,
    minDepartureDate,
    maxDepartureDate,
  } = useMemo(() => {
    const minStay = departureAvailabilities?.min_stay ?? 1;
    const maxStay = departureAvailabilities?.max_stay ?? 1;
    const minDepartureDate = addDays(arrival ?? currentDate, minStay);
    const maxDepartureDate = addDays(arrival ?? currentDate, maxStay);

    return {
      departureDays:
        departureAvailabilities?.departure_days.map((day) => parseDate(day)) ??
        [],
      minStay,
      maxStay,
      minDepartureDate,
      maxDepartureDate,
    };
  }, [
    arrival,
    departureAvailabilities?.departure_days,
    departureAvailabilities?.max_stay,
    departureAvailabilities?.min_stay,
    currentDate,
  ]);

  // invalid days modifier
  const { invalidDays } = useMemo(() => {
    const invalidDays: Matcher[] = [
      (day: Date) => {
        const invalidReason = isDayInvalid(day, {
          currentDate,
          arrival,
          departure,
          arrivalDays,
          departureDays,
          minStay,
          maxStay,
          minDepartureDate,
          maxDepartureDate,
        });

        if (invalidReason) {
          return true;
        }

        return false;
      },
    ];

    return {
      invalidDays,
      minDepartureDate,
      maxDepartureDate,
    };
  }, [
    currentDate,
    minDepartureDate,
    maxDepartureDate,
    arrival,
    departure,
    arrivalDays,
    departureDays,
    minStay,
    maxStay,
  ]);

  // selection range
  const range: DateRange | undefined = useMemo(() => {
    if (!arrival) {
      return;
    }

    return {
      from: arrival,
      to: departure ?? undefined,
    };
  }, [arrival, departure]);

  const updateMonthRef = useCallback(
    (monthRef: HTMLDivElement | null, calendarMonth: CalendarMonth) => {
      if (!arrival) {
        setInitScroll(false);
        return;
      }

      if (!initScroll || !isSameMonth(calendarMonth.date, arrival)) {
        return;
      }

      setScrollToMonthRef(monthRef);
    },
    [arrival, initScroll],
  );

  const calendarContextValue: CalendarContextValue = useMemo(() => {
    return {
      arrival,
      arrivalAvailabilitiesMap,
      arrivalDays,
      minStay,
      maxStay,
      minDepartureDate,
      maxDepartureDate,
      departure,
      departureDays,
      updateMonthRef,
    };
  }, [
    arrival,
    arrivalAvailabilitiesMap,
    arrivalDays,
    departure,
    departureDays,
    maxDepartureDate,
    maxStay,
    minDepartureDate,
    minStay,
    updateMonthRef,
  ]);

  const setFormArrivalAndDeparture = (
    newArrival: Date | null,
    newDeparture: Date | null,
  ) => {
    if (newArrival !== arrival) {
      setValue("arrival", newArrival, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
      setButtonGroupArrival(newArrival);
    }

    if (newDeparture !== departure) {
      setValue("departure", newDeparture, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
      setButtonGroupDeparture(newDeparture);
    }
  };

  const handleSelect = (
    selectedRange: DateRange | undefined,
    selectedDay: Date,
    { range_start: rangeStart }: Modifiers,
  ) => {
    // nothing yet selected - set from
    if (!range?.from) {
      setFormArrivalAndDeparture(selectedRange?.from ?? null, null);
      return;
    }

    // clicked on already selected arrival - reset selection
    if (rangeStart) {
      setFormArrivalAndDeparture(null, null);
      return;
    }

    // selecting an earlier arrival date and hence swapping arrival/departure is not allowed
    if (isBefore(selectedDay, range.from)) {
      setFormArrivalAndDeparture(selectedDay, null);
      return;
    }

    // if selection exist and click somewhere else than arrival/departure start new selection
    if (range.to) {
      setFormArrivalAndDeparture(selectedDay, null);
      return;
    }

    // not full range selected yet - set selection from picker
    setFormArrivalAndDeparture(
      selectedRange?.from ?? null,
      selectedRange?.to ?? null,
    );
  };

  const onMonthChange = useCallback(
    (month: Date) => {
      if (isDesktop) {
        const monthsOffset = differenceInMonths(
          month,
          startOfMonth(currentDate),
        );
        setArrivalAvailabilitiesSize(monthsOffset + desktopNumberOfMonths);
      }

      setMonth(month);
    },
    [isDesktop, currentDate, setArrivalAvailabilitiesSize, setMonth],
  );

  return (
    <>
      <MobileModalHeader
        title={
          <>
            {arrival ? (
              <DateComponent
                date={arrival}
                formatter={formatIntlDate(language)}
              />
            ) : (
              <span className={styles.highlighted}>{i18n.start.arrival}</span>
            )}
            &nbsp;--&gt;&nbsp;
            {departure ? (
              <DateComponent
                date={departure}
                formatter={formatIntlDate(language)}
              />
            ) : (
              <span className={arrival ? styles.highlighted : undefined}>
                {i18n.start.departure}
              </span>
            )}
          </>
        }
        onClose={() => setStep(BookingStep.Start)}
      />
      <MobileModalContent ref={modalContentRef}>
        <div className={styles.calendarWrapper}>
          <input
            hidden
            disabled
            {...register("arrival", {
              required: true,
            })}
          />
          <input
            hidden
            disabled
            {...register("departure", {
              required: true,
            })}
          />
          <CalendarHeader />
          <CalendarContext.Provider value={calendarContextValue}>
            <DayPicker
              locale={getLocaleByLanguage(language)}
              mode="range"
              selected={range}
              modifiers={{
                arrival: arrivalDays,
                departure: departureDays,
                invalid: invalidDays,
              }}
              modifiersClassNames={{
                arrival: "arrival",
                departure: "departure",
                invalid: styles.invalidDay,
                outside: styles.outside,
              }}
              startMonth={currentDate}
              endMonth={addMonths(currentDate, maxMonths)}
              defaultMonth={isDesktop ? (arrival ?? currentDate) : currentDate}
              month={month}
              numberOfMonths={
                isDesktop ? desktopNumberOfMonths : arrivalAvailabilitiesSize
              }
              disableNavigation={!isDesktop}
              hideWeekdays={!isDesktop}
              hideNavigation={!isDesktop}
              onMonthChange={onMonthChange}
              onSelect={handleSelect}
              components={{
                MonthCaption: CalendarMonthCaption,
                DayButton: CalendarDayButton,
              }}
              classNames={{
                root: "",
                months: styles.months,
                month: styles.month,
                "month_caption": styles.caption,
                nav: styles.nav,
                "month_grid": styles.table,
                weekday: styles.headCell,
                week: "",
                "caption_label": styles.captionLabel,
                day: styles.cell,
                "day_button": styles.day,
                selected: styles.daySelected,
                "range_start": "",
                "range_middle": styles.dayRangeMiddle,
                "button_previous": clsx(
                  styles.button,
                  styles.navButtonPrevious,
                ),
                "button_next": clsx(styles.button, styles.navButtonNext),
                chevron: styles.buttonIcon,
              }}
            />
          </CalendarContext.Provider>
          <div ref={loadMoreMonthsRef}></div>
        </div>
      </MobileModalContent>
      {arrival && departure && (
        <ActionFooter className={styles.actionFooter}>
          <CalendarFooter arrival={arrival} departure={departure} />
        </ActionFooter>
      )}
    </>
  );
};

export default CalendarForm;
