import { useFieldArray, useFormContext } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Date from "../../ui/Date";
import NumberInput from "../../ui/NumberInput";
import Select from "../../ui/Select";
import { formatIntlDate, parseDate } from "../../utils/date";
import styles from "./ExtraPerGuestAndDayChildren.module.css";
import { ExtraFormQuantity, ExtraFormType } from "./utils";

interface ExtraPerGuestAndDayChildrenProps {
  quantity: ExtraFormQuantity;
  quantityIndex: number;
  childrenAges: number[];
  maxChildrenCount: number;
  className?: string;
}

const ExtraPerGuestAndDayChildren = ({
  quantity,
  quantityIndex,
  childrenAges,
  maxChildrenCount,
  className = "",
}: ExtraPerGuestAndDayChildrenProps) => {
  const language = useBookingStore((state) => state.language);

  const { control } = useFormContext<ExtraFormType>();
  const {
    fields: childrenFields,
    append,
    remove,
  } = useFieldArray({
    name: `quantities.${quantityIndex}.children`,
    control,
  });

  return (
    <NumberInput
      className={className}
      title={
        <Date
          date={parseDate(quantity.key)}
          formatter={formatIntlDate(language)}
        />
      }
      value={childrenFields.length}
      min={0}
      max={maxChildrenCount}
      onChange={(children) =>
        children > childrenFields.length
          ? append({ age: -1 })
          : remove(childrenFields.length - 1)
      }
    >
      {childrenFields.length > 0 && (
        <div className={styles.childrenAges}>
          {childrenFields.map((childrenField, index) => (
            <ChildAge
              key={childrenField.id}
              quantityIndex={quantityIndex}
              childrenAges={childrenAges}
              index={index}
            />
          ))}
        </div>
      )}
    </NumberInput>
  );
};

interface ChildAgeProps {
  quantityIndex: number;
  childrenAges: number[];
  index: number;
}

const ChildAge = ({ childrenAges, quantityIndex, index }: ChildAgeProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const { register, watch } = useFormContext<ExtraFormType>();

  const registerProps = register(
    `quantities.${quantityIndex}.children.${index}.age`,
    {
      valueAsNumber: true,
      validate: (value) => value >= 0,
    },
  );

  const [selectedChildren, currentAge] = watch([
    `quantities.${quantityIndex}.children`,
    `quantities.${quantityIndex}.children.${index}.age`,
  ]);

  const unusedChildrenAges = childrenAges.slice();
  selectedChildren?.forEach((x) => {
    const index = unusedChildrenAges.indexOf(x.age);
    if (index > -1) {
      unusedChildrenAges.splice(index, 1);
    }
  });

  const options = childrenAges
    .filter((x, index, array) => array.indexOf(x) === index)
    .sort()
    .map((x) => {
      return {
        key: `${x}`,
        value: `${x}`,
        disabled: !(x === currentAge || unusedChildrenAges.includes(x)),
      };
    });

  return (
    <div className={styles.childrenAgeWrapper}>
      <Select
        label={i18n.extras.childrenAge(index + 1)}
        className={styles.childrenAgeSelect}
        classNameIcon={styles.childrenAgeSelectIcon}
        placeholder={i18n.extras.selectAge}
        placeholderValue={-1}
        options={options}
        {...registerProps}
      />
    </div>
  );
};

export default ExtraPerGuestAndDayChildren;
