import { Fieldset, Legend } from "@headlessui/react";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import NumberInput from "../../ui/NumberInput";
import ArrowDown from "../../ui/icon/arrow-down.svg?react";
import Trash from "../../ui/icon/trash.svg?react";
import { site } from "../../utils/site";
import OccupancyChildren from "./OccupancyChildren";
import styles from "./OccupancyRoom.module.css";
import { useOccupancyFormContext } from "./utils";

interface OccupancyRoomProps {
  open: boolean;
  index: number;
  onOpen: (index: number) => void;
  onDelete: () => void;
}

const OccupancyRoom = ({
  open,
  index,
  onOpen,
  onDelete,
}: OccupancyRoomProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const { setValue, control, watch, formState } = useOccupancyFormContext();
  const visualIndexValue = index + 1;
  const [occupancies, adults, children] = watch([
    "occupancies",
    `occupancies.${index}.adults`,
    `occupancies.${index}.children`,
  ]);
  const maxAdults = site.max_guests_per_room - children.length;
  const maxChildren = site.children_allowed
    ? site.max_guests_per_room - adults
    : 0;

  return (
    <Fieldset className={styles.room}>
      <div className={styles.header}>
        <Button
          layout="link"
          buttonProps={{
            onClick: () => onOpen(index),
            title: `${i18n.occupancy.room(site.room_term)} ${visualIndexValue}`,
            disabled: !formState.isValid,
            className: clsx(styles.opener, {
              [styles.activeOpener]: open,
            }),
          }}
        >
          <Legend as="span">
            {`${i18n.occupancy.room(site.room_term)} ${visualIndexValue}`}
            {!open &&
              `: ${i18n.occupancy.guestsCountSummary(adults + children.length)}`}
          </Legend>
        </Button>
        <div className={styles.actions}>
          {occupancies.length > 1 && (
            <Button
              layout="link"
              buttonProps={{
                onClick: () => {
                  onDelete();
                },
                "aria-label": i18n.general.delete,
                className: styles.deleteButton,
              }}
            >
              <Trash className={styles.deleteIcon} />
            </Button>
          )}
          <Button
            layout="icon"
            buttonProps={{
              onClick: () => onOpen(index),
              "aria-label": i18n.general.expand,
              className: clsx(styles.expandButton, {
                [styles.expandButtonActive]: open,
              }),
              disabled: !formState.isValid,
            }}
            glyph={ArrowDown}
          />
        </div>
      </div>
      {open && (
        <div className={styles.content}>
          <Controller
            name={`occupancies.${index}.adults`}
            control={control}
            rules={{
              min: 1,
              max: maxAdults,
            }}
            render={({ field: { value } }) => (
              <NumberInput
                title={i18n.occupancy.adults}
                value={value}
                min={1}
                max={maxAdults}
                description={i18n.occupancy.adultsInfo}
                onChange={(adults) =>
                  setValue(`occupancies.${index}.adults`, adults, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  })
                }
              />
            )}
          />
          {site.children_allowed && (
            <OccupancyChildren
              occupancyIndex={index}
              maxChildrenCount={maxChildren}
              childrenMinAge={site.children_min_age}
            />
          )}
        </div>
      )}
    </Fieldset>
  );
};

export default OccupancyRoom;
