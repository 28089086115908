import {
  AutoOccupancy,
  BookingStep,
  HeaderButtonCategory,
  Occupancy,
  Occupancy as OccupancyType,
} from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import Form from "../../ui/Form";
import MobileModal from "../../ui/MobileModal";
import { site } from "../../utils/site";
import OccupancyForm from "./OccupancyForm";
import styles from "./OccupancyModal.module.css";
import {
  FormDataOccupancies,
  getBaseFormDataOccupancy,
  getFormDataOccupancy,
} from "./utils";

const OccupancyModal = () => {
  const step = useBookingStore((state) => state.step);
  const occupancies = useBookingStore((state) => state.occupancies);
  const setOccupancies = useBookingStore((state) => state.setOccupancies);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const setAutoOccupancy = useBookingStore((state) => state.setAutoOccupancy);
  const activeHeaderButton = useBookingStore(
    (state) => state.activeHeaderButton,
  );
  const setActiveHeaderButton = useBookingStore(
    (state) => state.setActiveHeaderButton,
  );
  const setStep = useBookingStoreSetStep();

  return (
    <MobileModal
      isOpen={activeHeaderButton === HeaderButtonCategory.Occupancy}
      onClose={() => setActiveHeaderButton(HeaderButtonCategory.None)}
    >
      <Form<FormDataOccupancies>
        defaultValues={{
          useAutoOccupancy: !!autoOccupancy,
          occupancies: autoOccupancy
            ? [getBaseFormDataOccupancy(autoOccupancy, site)]
            : occupancies.map((occupancy: OccupancyType) =>
                getFormDataOccupancy(occupancy, site),
              ),
        }}
        className={styles.form}
        onSubmit={(data) => {
          if (didSomethingChange(data, autoOccupancy, occupancies)) {
            const newOccupancies = data.occupancies.map((occupancy) => ({
              adults: occupancy.adults,
              children: occupancy.children.map((x) => x.age),
              roomTypeId: occupancy.roomTypeId,
            }));

            if (data.useAutoOccupancy) {
              if (newOccupancies[0]) {
                setAutoOccupancy({
                  adults: newOccupancies[0].adults,
                  children: newOccupancies[0].children,
                });
              }
            } else {
              setAutoOccupancy(null);
              setOccupancies(newOccupancies);
            }
            setStep(BookingStep.Calendar);
            return;
          }

          if ([BookingStep.Start, BookingStep.Occupancy].includes(step)) {
            setStep(BookingStep.Calendar);
          } else {
            setActiveHeaderButton(HeaderButtonCategory.Calendar);
          }
        }}
      >
        <OccupancyForm />
      </Form>
    </MobileModal>
  );
};

export default OccupancyModal;

const didSomethingChange = (
  data: FormDataOccupancies,
  autoOccupancy: AutoOccupancy | null,
  occupancies: Occupancy[],
) => {
  if (!!autoOccupancy !== data.useAutoOccupancy) {
    return true;
  }

  if (data.useAutoOccupancy) {
    const newAutoOccupancy = data.occupancies.map((occupancy) => ({
      adults: occupancy.adults,
      children: occupancy.children.map((x) => x.age),
    }))[0];

    return autoOccupancyChanged(autoOccupancy, newAutoOccupancy);
  }

  const newOccupancies = data.occupancies.map((occupancy) => ({
    adults: occupancy.adults,
    children: occupancy.children.map((x) => x.age),
    roomTypeId: occupancy.roomTypeId,
  }));

  return occupanciesChanged(occupancies, newOccupancies);
};

const autoOccupancyChanged = (
  oldOccupancy: AutoOccupancy | null | undefined,
  newOccupancy: AutoOccupancy | null | undefined,
): boolean => {
  if (!oldOccupancy && !newOccupancy) {
    return false;
  }

  if (!oldOccupancy || !newOccupancy) {
    return true;
  }

  const oldChildrenString = oldOccupancy.children.join("_");
  const newChildrenString = newOccupancy.children.join("_");
  return (
    oldOccupancy.adults !== newOccupancy.adults ||
    oldChildrenString !== newChildrenString
  );
};

const occupancyChanged = (
  oldOccupancy: Occupancy,
  newOccupancy: Occupancy,
): boolean => {
  const oldChildrenString = oldOccupancy.children.join("_");
  const newChildrenString = newOccupancy.children.join("_");
  return (
    oldOccupancy.adults !== newOccupancy.adults ||
    oldOccupancy.roomTypeId !== newOccupancy.roomTypeId ||
    oldChildrenString !== newChildrenString
  );
};

const occupanciesChanged = (
  oldOccupancies: Occupancy[],
  newOccupancies: Occupancy[],
): boolean => {
  if (oldOccupancies.length !== newOccupancies.length) {
    return true;
  }
  return oldOccupancies.some((oldOccupancy, index) => {
    const newOccupancy = newOccupancies[index];
    return !newOccupancy || occupancyChanged(oldOccupancy, newOccupancy);
  });
};
