import { Field, Select as HeadlessSelect, Label } from "@headlessui/react";
import clsx from "clsx";
import { ChangeEventHandler, FocusEventHandler } from "react";
import styles from "./Select.module.css";
import ArrowDown from "./icon/arrow-down.svg?react";

interface Option {
  key: string;
  value: string;
  disabled?: boolean;
  selected?: boolean;
}

interface SelectProps {
  options: Option[];
  name: string;
  label?: string;
  autocomplete?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  required?: boolean;
  size?: number;
  placeholder?: string;
  placeholderValue?: string | number | readonly string[];
  className?: string;
  classNameIcon?: string;
  value?: string | number | readonly string[];
  defaultValue?: string | number | readonly string[];
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
}

const Select = ({
  options,
  name,
  label,
  placeholder,
  placeholderValue = "duiatrneiudaetrunia",
  className,
  classNameIcon,
  value,
  defaultValue,
  onChange,
  onBlur,
  ...rest
}: SelectProps) => {
  return (
    <Field className={styles.selectWrapper}>
      {label && <Label>{label}</Label>}
      <div className={styles.selectContainer}>
        <HeadlessSelect
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          className={clsx(styles.select, className)}
          value={value}
          defaultValue={defaultValue}
          {...rest}
        >
          {placeholder && (
            <option
              disabled
              hidden
              className={styles.option}
              value={placeholderValue}
            >
              {placeholder}
            </option>
          )}
          {options.map((option: Option) => (
            <option
              className={styles.option}
              key={option.key}
              value={option.key}
              disabled={option.disabled}
            >
              {option.value}
            </option>
          ))}
        </HeadlessSelect>
        <ArrowDown className={clsx(styles.arrow, classNameIcon)} aria-hidden />
      </div>
    </Field>
  );
};

export default Select;
