import { eachDayOfInterval } from "date-fns";
import { ExtraConfiguration, RoomConfiguration } from "../../@types";
import { Extra, ExtraType } from "../../http/extraApi";
import { formatDate } from "../../utils/date";

export interface ExtraProps {
  extra: Extra;
}

export interface ExtraFormType {
  quantities: ExtraFormQuantity[];
}

export interface ExtraFormQuantity {
  key: string;
  quantity?: number;
  adults?: number;
  children?: FormDataChildrenAge[];
}

interface FormDataChildrenAge {
  age: number;
}

export const getDefaultValues = (
  extra: Extra,
  extraConfigurations: Record<string, ExtraConfiguration>,
  arrival: Date,
  departure: Date,
  roomConfigurations: RoomConfiguration[],
): ExtraFormType => {
  const extraConfiguration = extraConfigurations[extra.id];
  switch (extra.type) {
    case ExtraType.PerStay:
      return {
        quantities: [
          {
            key: extra.type,
            quantity: extraConfiguration?.quantities[extra.type]?.quantity ?? 0,
          },
        ],
      };
    case ExtraType.PerRoom:
      return {
        quantities: roomConfigurations.map((x) => {
          const key = `${x.roomIndex}`;
          return {
            key,
            quantity: extraConfiguration?.quantities[key]?.quantity ?? 0,
          };
        }),
      };
    case ExtraType.PerDay: {
      const days = eachDayOfInterval({
        start: arrival,
        end: departure,
      });
      return {
        quantities: days.map((day) => {
          const key = formatDate(day);
          return {
            key,
            quantity: extraConfiguration?.quantities[key]?.quantity ?? 0,
          };
        }),
      };
    }
    case ExtraType.PerGuestAndDay: {
      const days = eachDayOfInterval({
        start: arrival,
        end: departure,
      });
      return {
        quantities: days.map((day) => {
          const key = formatDate(day);
          return {
            key,
            adults: extraConfiguration?.quantities[key]?.adults ?? 0,
            children:
              extraConfiguration?.quantities[key]?.children?.map((x) => ({
                age: x,
              })) ?? [],
          };
        }),
      };
    }
  }
};

export const getQuantityCount = (quantities: ExtraFormQuantity[]) =>
  quantities.reduce((count, quantity) => {
    count += quantity.quantity ?? 0;
    count += quantity.adults ?? 0;
    count += quantity.children?.length ?? 0;

    return count;
  }, 0);
