import { Field, Checkbox as HeadlessCheckbox, Label } from "@headlessui/react";
import { JSX, Ref, useId } from "react";
import { FieldError } from "react-hook-form";
import styles from "./Checkbox.module.css";
import FormError from "./FormError";
import Check from "./icon/check.svg?react";

interface CheckboxProps {
  name: string;
  label: JSX.Element | string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  error?: FieldError | undefined;
  ref: Ref<HTMLInputElement>;
}

const Checkbox = ({
  name,
  label,
  disabled = false,
  className,
  error,
  required,
  ref,
  ...rest
}: CheckboxProps) => {
  const errorId = useId();

  return (
    <Field className={className}>
      <div className={styles.checkboxWrapper}>
        <HeadlessCheckbox
          ref={ref}
          className={styles.checkbox}
          aria-invalid={!!error}
          aria-required={required}
          aria-errormessage={error ? errorId : undefined}
          name={name}
          disabled={disabled}
          {...rest}
        >
          <Check className={styles.icon} aria-hidden />
        </HeadlessCheckbox>
        <Label className={styles.label}>
          {label}
          {required && "*"}
        </Label>
      </div>
      <FormError id={errorId} errorMessage={error?.message ?? ""} />
    </Field>
  );
};

export default Checkbox;
