import clsx from "clsx";
import { EmblaCarouselType } from "embla-carousel";
import { ComponentPropsWithRef, useCallback, useEffect, useState } from "react";
import styles from "./SliderDots.module.css";

interface UseDotButtonType {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
}

export const useDotButton = (
  emblaAPI: EmblaCarouselType | undefined,
): UseDotButtonType => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onDotButtonClick = useCallback(
    (index: number) => {
      emblaAPI?.scrollTo(index);
    },
    [emblaAPI],
  );

  const onInit = useCallback((emblaAPI: EmblaCarouselType) => {
    setScrollSnaps(emblaAPI.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaAPI: EmblaCarouselType) => {
    setSelectedIndex(emblaAPI.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaAPI) return;

    onInit(emblaAPI);
    onSelect(emblaAPI);
    emblaAPI.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [emblaAPI, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
};

type DotButtonProps = ComponentPropsWithRef<"button"> & {
  active?: boolean;
};

export const DotButton = ({
  children,
  active,
  ...restProps
}: DotButtonProps) => {
  return (
    <button
      className={clsx(styles.dot, { [styles.active]: active })}
      type="button"
      {...restProps}
    >
      {children}
    </button>
  );
};
